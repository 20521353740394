<template>
  <div class="categories">
    <div class="page-header">
      <h1>{{ $t('Menu Management') }} > {{ $t('Categories') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Categories') }}</h2>
        <router-link to="/categorie/add">
          <el-button class="action-button" type="primary">{{ $t('Create Categories') }}</el-button>
        </router-link>
      </div>

      <div class="categories">
        <ul class="list-group">
          <li class="list-group-item" v-for="categorie in categories">
            <div class="categorie-title" v-if="langcode === 'en'">
              {{ categorie.categorie_name_en }}
            </div>
            <div class="categorie-title" v-if="langcode === 'zh'">
              {{ categorie.categorie_name_zh }}
            </div>
            <div class="action-buttons">
              <router-link :to="{name:'EditCategorie', params: {id: categorie.id}}">
                <el-button class="action-button" plain>{{ $t('Edit') }}</el-button>
              </router-link>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Menu from '@/lib/menu';

export default {
  name: 'Categories',
  data(){
    return {
      originalCategories: [],
      categories: [],
    }
  },
  mounted(){
    this.loadCategories();
  },
  methods:{
    async loadCategories(){
      const loginInfo = Common.getLoginInfo();
      try{
        const categories = await Menu.loadAllCategories(this.apiUrl, loginInfo);
        this.categories = categories;
        this.originalCategories = categories;
      }catch(err){
        console.log(err);
      }
    },
    async deleteCategorie(categorieId){
      const loginInfo = Common.getLoginInfo();
      try{
        await Meal.deleteCategorie(this.apiUrl, categorieId, loginInfo);
        const newCategories = this.categories.filter(function(item) {
          return item.id !== categorieId;
        });
        this.categories = newCategories;
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style scoped lang="scss">
.categorie-title{
  color: black;
}
.list-group{
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 30px;

  .list-group-item{
    background-color: #FAFAFA;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    .categorie-title{
      flex: 1;
    }
  }
}
</style>
